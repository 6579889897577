import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled from 'styled-components';
import { GlobalStyle } from './GlobalStyle.js';

const HeaderBackground = styled.div`
  background-color: #002768;
`;

const HeaderWrapper = styled.div`
  max-width: 960px;
  min-height: 140px;
  color: white;
  margin: 0px auto;
  text-decoration: none;
`;

const BrandImage = styled.img`
  height: 140px;
  width: 140px;
  display: inline-block;
  margin: 0px;
  float: left;
  -webkit-filter: drop-shadow(5px 5px 5px #1F1F23);
  filter: drop-shadow(5px 5px 5px #1F1F23);
`;

const HeaderText = styled.div`
  min-height: 100%;
  display: inline-block;
  float: left;
`;

const HeaderTitle = styled.h1`
  display: block;
  font-family: Cinzel;
  font-size: 72px;
  text-shadow: 3px 3px #1F1F23;
  padding-top: 18px;
  padding-bottom: 0px;
  margin-bottom: 0px;
`;

const HeaderSubtitle = styled.ul`
  display: block;
  font-family: Oxygen;
  font-size: 24px;
  padding-top: 0px;
  margin-top: 0px;
  margin-left: 0px;
`;

const SubtitleItem = styled.li`
  display: inline;
  padding-right: 0px;
  padding-left: 0px;
  list-style-type: none;
  list-style-position: outside;
`;

const Body = styled.div`
  margin: 0px auto;
  max-width: 960px;
  padding: 0px 1.0875rem 1.45rem;
  padding-top: 0px;
`;

const Header = () => (
  <HeaderBackground>
    <HeaderWrapper>
      <BrandImage
        src="https://panterasbox.s3-us-west-1.amazonaws.com/rings-transparent.png"
      />
      <HeaderText>
        <HeaderTitle>
          Pantera's Box
        </HeaderTitle>
        <HeaderSubtitle>
          <SubtitleItem>Streaming Community {`\u2022`} </SubtitleItem>
          <SubtitleItem>Coder's Workshop {`\u2022`} </SubtitleItem>
          <SubtitleItem>Game Studio</SubtitleItem>
        </HeaderSubtitle>
      </HeaderText>
    </HeaderWrapper>
  </HeaderBackground>
);

const TemplateWrapper = ({ children }) => (
  <div>
    <GlobalStyle />
    <Helmet
      title="Pantera's Box"
      meta={[
        { name: 'description', content: 'Streaming community and game developer workshop.' },
        { name: 'keywords', content: 'twitch, programming, games' },
      ]}
      link={[
        { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Cinzel' },
        { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Oxygen' }
      ]}
    />
    <Header />
    <Body>
      {children}
    </Body>
  </div>
)

TemplateWrapper.propTypes = {
  children: PropTypes.func,
}

export default TemplateWrapper;
